import React from "react";
import "./contact.css"
import Head from "../home/head";
import Footer from "../home/footer";
import  { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FiPhone } from "react-icons/fi";
import { MdOutlineMail } from "react-icons/md";
import { MdOutlinePlace } from "react-icons/md";
import { FaWhatsappSquare } from "react-icons/fa";
import { FaTelegramPlane } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa6";
import { FaFacebook } from "react-icons/fa";
import { FaInstagramSquare } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import QRCode from "react-qr-code";
import { BsDisplay } from "react-icons/bs";
import axios from "axios";
import { Navigate } from "react-router-dom";


function Contact() {
  const navigate= useNavigate()
    const[validation, setval] = useState("");
    const [pname, setname] = useState("");
    const [localisation, setlocalisation] = useState("");
    const [numero, setnumero] = useState("");
    const [Email, setmail] = useState("");
    const [message, setmessage] = useState("");
    const changeN = (e) => {
        setname(e.target.value);
        
      };
      const changeNu = (e) => {
        setnumero(e.target.value);
        
      };
      const changeE = (e) => {
        setmail(e.target.value);
        
      };
      const changeL = (e) => {
        setlocalisation(e.target.value);
        
      };
      const changeM = (e) => {
        setmessage(e.target.value);
        
      };

      const send = async () => {
        axios
          .post("https://api.sanypower.net/groupe/email/", {
            name: pname,
            localisation: localisation,
            numero : numero,
            email:Email,
            message:message,
          })
          .then((response) => {
            console.log(response)
            setlocalisation("")
            setmail("")
            setmessage("")
            setnumero(" ")
            setname("")
            setval("votre message a tres bien ete envoyé")
            setTimeout(() => {
              
              setval("")
            }, "3000");
            
          })}
      
    return (
      <div className="contact">
        <Head />
      <div className="co">

        <div className="in">
        
          <div className="info">
           <h3>CONTACT</h3>
           <div>
            <p><MdOutlinePlace /> Localisation : Yopougon pk22 , en face de l'entreprise colas</p>
            <p><FiPhone/> Numero : +225 0713488888 / 0584282828 </p>
            <p><MdOutlineMail /> E-mail : generator@Sanypowergroup.com</p>
            <hr/>
            
            <h3>LIEN VERS NOS DIFFERENTS RESEAUX SOCIAUX</h3>
            <a>Whattsapp </a>
            <a>Facebook  </a>
            
            <a>Instagram </a>
            <a>X  </a>
            
            
            
            <hr/>
            <div className="codes">
            <div className="qr">
              <QRCode
              className="qc"
              size={200}
              bgColor="white"
              fgColor="green"
              value="https://wa.me/qr/MHLIQD5PWQO4E1"
              
              />
              <p>Whattsapp</p>

            </div>
            <div className="qr">
              <QRCode
              className="qc"
              size={200}
              bgColor="white"
              fgColor="black"
              value="https://www.instagram.com/sanypower24?igsh=MWtrdWl1ZW92b3Z0dQ=="
              
              />
              <p>instagram</p>

            </div>
            </div>
            
           </div>

          </div>

          <div className="mpp">
            <iframe width="100%" height="716" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=yopougon%20pk22%20,entreprise%20colas%20+(Sany%20Power)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed">
            <a href="https://www.gps.ie/">gps vehicle tracker</a>
            </iframe>
            </div>


        </div>

        
        <h2>LAISSEZ UN MESSAGE !</h2>

        <div className="coto">
          {validation}

        <div className="f1">
        <div className="f">
          <input
            id="cot"
            type="text"
            placeholder="Entrez votre nom"
            onChange={changeN}
            value={pname}
            
            required
          />
        </div>

        <div className="f">
          <input
            id="cot"
            type="number"
            placeholder="Entrez votre numero de telephone"
            onChange={changeNu}
            value={numero}
            required
          />
        </div>
        </div>


        <div className="f1">
        <div className="f">
          <input
            id="cot"
            type="email"
            placeholder="Entrez votre addresse Email"
            onChange={changeE}
            value={Email}
            required
          />
        </div>

        <div className="f">
          <input
            id="cot"
            type="text"
            placeholder="Veuillez entrer votre localisation"
            onChange={changeL}
            value={localisation}
            required
          />
        </div>
        </div>

        <div className="f1">
        
          <textarea placeholder="veuillez entrez votre message" onChange={changeM} value={message} ></textarea>
        </div>
        
          <div className="bto"> 
            <button onClick={send}>Envoyer</button>
            </div>
        </div>
      
      
    </div>
    <Footer></Footer>
    </div>
      );
}
export default Contact;