import React from "react";
import Head from "../home/head";
import Footer from "../home/footer";
import "./presentation.css"
import { CiLight } from "react-icons/ci";
function Presentation() {
    
  
   
    return (
        <div className="pres">

          <Head></Head>
          <div className="presentation">
            <div className="presentation1">
                    <div className="txt1"> Hunan Super Nouvelle Énergie Co., Ltd. est situé à Changsha, dans la province du Hunan, « la capitale des machines de construction en Chine ». Il s'agit d'une entreprise globale intégrant la R&D, la production et la vente de moteurs, de groupes électrogènes et de composants majeurs.</div>
                    <div className="img1"> <img src="https://sanypower.uz/wp-content/uploads/2022/07/4r.png"/></div>
            </div>
            <div className="P1">
            
            <p>Produits pour moteurs diesel : SANY POWER est spécialisé dans les moteurs destinés aux applications industrielles, principalement pour les zones tout-terrain. Recherche et développement de produits basés sur une vaste expérience appliquée, 10 ans d'accumulation de technologies, s'appuyant sur les solides capacités de R&D du centre de technologie d'entreprise de la province du Hunan et citant l'institut de recherche du sud-ouest et d'autres instituts de recherche de première classe de renommée mondiale, synchronisation des technologies de base avec les technologies internationales. ceux. Les moteurs de la série SANY POWER produits par la société sont largement utilisés dans l'ingénierie mécanique, l'amarrage des navires, la production d'électricité et d'autres domaines, avec les avantages d'une puissance élevée, d'une fiabilité et d'une durabilité, d'un rendement élevé et d'une faible consommation, de la protection de l'environnement et des économies d'énergie. SANY POWER produit des moteurs à essence et diesel avec une puissance moteur unique de 20 à 1 200 kilowatts pour devenir la principale source d'énergie pour les équipementiers du secteur. </p><br/>
            <br/>
            Produits pour groupes électrogènes : après des efforts de développement continus, la société actuelle dispose de solides capacités de R&D et de production de groupes électrogènes. L'entreprise produit des groupes électrogènes d'une capacité unitaire de 20 à 3 000 kilowatts. Elle dispose de moteurs diesel domestiques de première classe, de générateurs, de lignes de production de traitement de boîtes silencieuses et de lignes de production pour la recherche et le développement de composants de base du système de contrôle, et dispose de plus de 100 équipements de production avancés. , instrumentation sophistiquée, procédures de test complètes, laboratoire conforme aux normes nationales, est une unité commune de contrôle de qualité de l'Institut de supervision et d'inspection de la qualité du Hunan. Les produits de la société ont passé la certification du système de gestion de la qualité ISO9001 : 2008 et la certification CE de l'UE . La production annuelle des trois principales bases de production chinoises est de 10 000 unités et la production annuelle est supérieure à 100 millions de yuans. Actuellement, elle a établi une coopération stratégique mondiale avec Cummins aux États-Unis, Perkins au Royaume-Uni, Weichai  et Yuchai . Les produits sont largement utilisés dans les chemins de fer, les télécommunications, la construction, la production d'électricité, l'armée, les usines et les mines, les autoroutes, les champs de pétrole, les navires et autres domaines. Les produits sont exportés vers l'Europe, l'Asie du Sud-Est, l'Asie centrale, la Russie, l'Amérique du Sud, l'Afrique et d'autres pays.
            </div>

           

            <h2>CAS A SUCCES</h2>

            <div className="presentation2">
                    <div>
                        <img src="https://images.pexels.com/photos/955394/pexels-photo-955394.jpeg"/>
                        <p>Licence pour faire des affaires en Ouzbékistan</p>
                    </div>
                    <div >
                        <img src="https://images.pexels.com/photos/5256816/pexels-photo-5256816.jpeg?auto=compress&cs=tinysrgb&w=1200"/>
                        <p>Permis pour les services d'agence commerciale SUPERPOWER</p>
                    </div>
                    <div><img src="https://images.pexels.com/photos/7735630/pexels-photo-7735630.jpeg?auto=compress&cs=tinysrgb&w=1200"/><p>Permis de service de l'agence commerciale Weichai</p></div>
                    <div><img src="https://images.pexels.com/photos/8428080/pexels-photo-8428080.jpeg?auto=compress&cs=tinysrgb&w=1200"/><p>Permis de service d'agence commerciale Yu Chai</p></div>
            </div>
            <h2>BUREAU DE LA REPRESENTATION DE LA SOCIETE EN COTE D'IVOIRE  </h2>
            
            Super Power LLC est engagé dans la vente de groupes électrogènes à essence, à gaz, au diesel et d'autres sources d'énergie de secours et autonome.<br/><br/>
            Super Power LLC propose une large gamme de groupes électrogènes à essence, gaz et diesel , de toute puissance allant de 1 kW à 2000 kW, ainsi que la fourniture de pièces de rechange et un service professionnel pour ceux-ci.
Nos produits sont nécessaires aussi bien aux propriétaires de maisons privées et de chalets d'été, qu'à toutes sortes d'organisations commerciales, d'institutions médicales, de chantiers de construction, d'industries pétrolière et agricole, ainsi que partout où une alimentation de secours ou autonome est nécessaire. <br/> <br/>
        
        Nous sommes prêts à vous proposer les types d’alimentations de secours et autonomes suivants : <br/> <br/>

        <div className="cubes">

          <div className="cube"> <CiLight /> <p>groupe électrogène diesel</p> </div>
          <div className="cube"><CiLight /><p>générateur de courant à essence</p></div>
          <div className="cube"><CiLight /><p>générateur de courant de gaz</p> </div>
          <div className="cube"><CiLight /><p>générateur de soudage diesel</p></div>
          <div className="cube"><CiLight /><p>mâts d'éclairage</p></div>
         
          
        </div>
        
       

        Vous pouvez acheter un générateur diesel dans différentes versions : un générateur dans un boîtier, dans un conteneur et sur un châssis. <br/>
        <h3>Nos Avantages </h3>

        – nous avons notre propre production ;<br/>
        – avoir toujours des produits en stock ;<br/>
        – toujours disponibilité des pièces de rechange en stock ;<br/>
        – un service hautement qualifié;<br/>
        – complet avec kit d'entretien primaire (huile, filtre à air, courroie d'alternateur, joint de culasse) ;<br/>
        – faire le plein de tous les fluides techniques (huile moteur, antigel) ;<br/>
        – les moteurs de nos groupes électrogènes essence, gaz, diesel proviennent de marques mondiales telles que : Cummins, Perkins, Shangchai,Weichai, Yuchai, etc.<br/><br/><br/>

        Super Power LLC garantit des produits et services de haute qualité.<br/><br/>

Lors de l'achat chez nous de groupes électrogènes essence, gaz ou diesel , nous proposons une gamme complète de services pour la préparation avant vente, la fourniture du matériel et son installation, avec une première mise en service sur site. Nous proposons également une formation à vos employés pour mieux utiliser les équipements.
          </div>
          
          <Footer></Footer>
          
        </div>
      );
}
export default Presentation;