import React from "react";
import Head from "../home/head";
import Footer from "../home/footer";
import "./liste.css"
import { useParams } from "react-router-dom";

import axios from "axios";
import  { useEffect, useState } from "react";

function Liste(props) {
  
   const params=useParams()
    const data={
      0:{
          'appp':'nnnn'
      },
      1:{
        'app':'nnnnn'
      },
    }

    const [contenu, setcontenu]=useState("")
    const [groupe,setgroupe] = useState("")
  


    const Partype =async()=>{
      axios.get(`https://api.sanypower.net/groupe/demande/${params.id}/`)
    .then((response) => setgroupe(response.data))
    .catch((error)=> console.log(error))
  
    }
  
    useEffect(() =>{
      Partype();
    },[])
  
    useEffect(() =>{
      if(groupe){
        console.log("dddddddddd")
        setcontenu( 
          <div className="cont">
            
            {groupe.map((item, idx) => {
           
        
        return (
          
          <div className="elm" >
            <a href={`/details/${item.app}`}>
          <img
            src={`https://api.sanypower.net${item.image}`}
            
            key={idx}
          
          />
            <div className="ecri">
              {item.app}
            </div>
            </a>
          </div>
         
        );
      })}
          </div>
      
      
      )
      }
      else{
        setcontenu(<div></div>)
       
      }
    },[groupe])
     
    console.log(groupe)
   
    return (
        <div className="liste">

          <Head></Head>
          <div className="liste1">
          <h2>Groupes electrogenes par types : {params.types} </h2>
            {contenu}
          </div>
          
          <Footer></Footer>
          
        </div>
      );
}
export default Liste;