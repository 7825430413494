import React from "react";
import Head from "../home/head";
import Footer from "../home/footer";
import "./catalogue.css"
import { useParams } from "react-router-dom";
import axios from "axios";
import  { useEffect, useState } from "react";
import Cours from "../home/coursel";
function Catalogue() {
    
  const params=useParams()

    const [contenu, setcontenu]= useState("")
    const [groupe,setgroupe] = useState("")
  


    const detail =async()=>{
      axios.get(`https://api.sanypower.net/groupe/detail/${params.name}/`)
    .then((response) => setgroupe(response.data))
    .catch((error)=> console.log(error))
  
    }
  
    useEffect(() =>{
      detail();
    },[])


    useEffect(() =>{
      if(groupe){
       console.log(groupe)
        setcontenu( 
          <div className="detail">
            <div className="detail1">
            <Cours className="" data ={groupe.images}/>
            </div>
            <div className="detail2">
              <h3>{groupe.name}</h3>
            name : {groupe.name}<br/>
            Modele du generateur : {groupe.Modele_du_generateur} <br/>
            tension : {groupe.tension}<br/>
            puissance : {groupe.puissance} <br/>
            moteur : {groupe. moteur } <br/>
            modele du moteur : {groupe.modele_du_moteur} <br/>
            carburant : {groupe.carburant} <br/>
             frequence : {groupe.frequence}
    
            </div>
          </div>
      
      
      )
      }
      else{
        setcontenu(<div></div>)
       
      }
    },[groupe])
    
   
    return (
        <div>

          <Head></Head>
          <div className="catalogue1">

          {contenu}
           
          </div>
          
          <Footer></Footer>
          
        </div>
      );
}
export default Catalogue;