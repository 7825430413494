import React, { Component } from "react";
import logo from './logo.svg';
import './App.css';

import{
  BrowserRouter as Router,
  Routes,
  
  Route,

}from "react-router-dom"

import Home from "./components/home/home"
import Presentation from "./components/presentation/presentation"
import Catalogue from "./components/catalogues/catalogue"
import Liste from "./components/liste/liste"
import Contact from "./components/contact/contact";
import Np from "./components/np/np";

function App() {
  return (
    <>
      <Router>
        <Routes>


        <Route exact path="/" Component={Home}/>
        <Route exact path="/presentation/" Component={Presentation}/>
        <Route exact path="/details/:name" Component={Catalogue}/>
        <Route exact path="/liste/:id/:types" Component={Liste}/>
        <Route exact path="/contact/" Component={Contact}/>
        <Route exact path="/catalogue/" Component={Np}/>
        </Routes>
        

          
        
      </Router>
    
    </>
  );
}

export default App;
