import React from "react";
import Head from "../home/head";
import Footer from "../home/footer";
import "./np.css"
import { useParams } from "react-router-dom";
import axios from "axios";
import  { useEffect, useState } from "react";

import photo1 from "../media/téléchargement.png";
import photo2 from "../media/Baudouin.jpg";
import photo3 from "../media/cummins.png";
import photo4 from "../media/yuchai.png";
import photo5 from "../media/perkins.png";
import photo6 from "../media/p2.jpg";

function Np() {
    
  
    return (
        <>

          <Head></Head>
          <div className="np">

          <div className="npc">
          <div className="p1n">



<div className="catn">
  <a href="/liste/1/WEICHAI">
      <img src={photo1}/>
      <div class="hover-image">
  
      </div>
      <p>WEICHAI</p>

  </a>
  
</div>

<div className="catn">
 
  <a href="/liste/2/BAUDOIN" >
      <img src={photo2}/>
      <div class="hover-image">
  
      </div>
      <p>BAUDOIN</p>

  </a>
 
</div>
<div className="catn">
  <a href="/liste/3/CUMMINS">
      <img src={photo3}/>
      <div class="hover-image">
  
      </div>
      <p>CUMMINS</p>

  </a>
  
</div>
<div className="catn">
  <a href="/liste/4/YUCHAI">
      <img src={photo4}/>
      <div class="hover-image">
  
      </div>
      <p>YUCHAI</p>

  </a>
  
</div>
<div className="catn">
  <a href="/liste/5/PERKINS">
      <img src={photo5}/>
      <div class="hover-image">
  
      </div>
      <p>PERKINS</p>

  </a>
  
</div>

<div className="catn">
  <a href="/liste/6/divers">
      <img src={photo1}/>
      <div class="hover-image">
  
      </div>
      <p>DIVERS....</p>

  </a>
  
</div>

 


</div>
          </div>
           
          </div>
          
          <Footer></Footer>
          
        </>
      );
}
export default Np;