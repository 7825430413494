import React from "react";
import "./head.css"
import  { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { FaList } from "react-icons/fa";
import { IoCloseOutline } from "react-icons/io5";

function Head() {
  const location =useLocation()
 
 const [sidebar, setSidebar] = useState(false);
 const showSidebar = () => setSidebar(!sidebar);
  
  

  
    
    return (
      <>
      <div className={sidebar ? "cp" : ""}></div>
      <div className="header">
      <a href="#default" className="logo">Sany Power</a>
      <div className="header-right">
        <a className={(location.pathname=="/")? "active":""} href="/" >Acceuil</a>
        <a className={(location.pathname=="/presentation")? "active":""} href="/presentation" >A propos</a>
         <a className={(location.pathname=="/catalogue/")? "active":""} href="/catalogue/" >Catalogue</a>
        <a className={(location.pathname=="/contact/")? "active":""} href="/contact/" >Contact</a>
        
      </div>
      <div className="header-r" >
       
      <FaList onClick={showSidebar} />
      </div>
    </div>
    <div className="banniere">

    <div className={sidebar ? "header-rightactive" : "hr"}>
   
      <ul>
       <li> <a className={(location.pathname=="/")? "active":""} href="/" >Acceuil</a></li>
       <li> <a className={(location.pathname=="/presentation/")? "active":""} href="/presentation/" >A propos</a></li>
       <li> <a className={(location.pathname=="/catalogue/")? "active":""} href="/catalogue/" >Catalogue</a></li>
       <li> <a className={(location.pathname=="/contact/")? "active":""} href="/contact/" >Contact</a></li>
        </ul>
      </div>

    
      
    </div>
    </>
      );
}
export default Head;