import React from "react";
import "./footer.css"
import { FiPhone } from "react-icons/fi";
import { MdOutlineMail } from "react-icons/md";
import { MdOutlinePlace } from "react-icons/md";
import { FaWhatsappSquare } from "react-icons/fa";
import { FaTelegramPlane } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa6";
import { FaFacebook } from "react-icons/fa";
import { FaInstagramSquare } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

function Footer() {
    
    return (
       
      <footer class="footer">
      <div class="waves">
        <div class="wave" id="wave1"></div>
        <div class="wave" id="wave2"></div>
        <div class="wave" id="wave3"></div>
        <div class="wave" id="wave4"></div>
      </div>
     
      <div className="catr">

      <div >
        <h4> Navigation</h4>
      <ul class="menu">
        <li class="menu__item"><a class="menu__link" href="/">Acceuil</a></li>
        <li class="menu__item"><a class="menu__link" href="/presentation/">A propos</a></li>
        <li class="menu__item"><a class="menu__link" href="/catalogue/">Catalogue</a></li>
        
        
        <li class="menu__item"><a class="menu__link" href="/contact/">Contact</a></li>
  
      </ul>
      </div>

      <div>
      <h4> Contact</h4>
      <ul class="menu">
        <li class="menu__item"><a class="menu__link" > <FiPhone/> Telephone : +225 0713488888 / 0584282828</a></li>
        <li class="menu__item"><a class="menu__link" ><MdOutlinePlace /> localisation : Yopougon pk22 , en face de l'entreprise colas</a></li>
        
        
        <li class="menu__item"><a class="menu__link" ><MdOutlineMail /> E-mail : generator@Sanypowergroup.com</a></li>
  
      </ul>
      </div>

      <div>
      <h4> Divers</h4>
      <ul class="menu">
        <li class="menu__item"><a class="menu__link" href="https://wa.me/qr/MHLIQD5PWQO4E1"><FaWhatsappSquare /> Wattsapp</a></li>
        
        <li class="menu__item"><a class="menu__link" href="#"><FaFacebook /> Facebook</a></li>
        <li class="menu__item"><a class="menu__link" href="https://www.instagram.com/sanypower24?igsh=MWtrdWl1ZW92b3Z0dQ=="><FaInstagramSquare /> Instagram</a></li>
        <li class="menu__item"><a class="menu__link" href="#"><FaXTwitter /> X</a></li>
        
       
  
      </ul>
      </div>

      </div>
     
      <p>&copy;SANY POWER | All Rights Reserved</p>
    </footer>
        
      );
}
export default Footer;